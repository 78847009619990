import React from "react";
import SizeSelector from "./SizeSelector";

const ProductDisplay = ({
  product,
  isExchange,
  isSelected,
  onChange,
  exchangeChoice,
}) => {
  // const handleSizeChange = () => {};

  return (
    <li
      className={
        isSelected ? "product-details" : "product-details item-disabled"
      }
      key={product.id}
    >
      <img
        style={{ maxWidth: "80px", padding: "20px" }}
        src={product.src}
        alt="LittleBox"
      />
      <div>
        {" "}
        <p>{product.title}</p>
        <p>Size: {product.variant_title} </p>
      </div>
      <div>
        {isExchange && (
          <SizeSelector
            exchangeChoice={exchangeChoice}
            product={product}
            onChange={onChange}
          />
        )}
      </div>
    </li>
  );
};

export default ProductDisplay;
