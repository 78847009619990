import { Box } from "@mui/material";
import React from "react";

const SuccessModal = ({ ticketId }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "60%",
    bgcolor: "#f9f4f5",
    boxShadow: 24,
    p: 4,
    fontFamily: "Helvetica sans-serif",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    borderRadius: "10px",
  };
  return (
    <Box sx={style}>
      <img
        style={{ objectFit: "contain", width: "180px" }}
        src="lblogo.jpeg"
        alt=""
      ></img>
      <h3>Your request has been submitted.</h3>
      <h3>
        Your Ticket Id is <span style={{ fontWeight: 800 }}>{ticketId}</span>{" "}
      </h3>
    </Box>
  );
};

export default SuccessModal;
