import React, { useState } from "react";
import { Alert } from "@mui/material";
import {
  CancelOutlined,
  CheckCircleOutline,
  WarningAmberOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const DeliveryStatus = ({ order }) => {
  const navigate = useNavigate();
  const handleTrackOrder = async () => {
    navigate("/trackyourorder", {
      state: { orderId: order.id },
    });
  };
  if (order.rto) {
    return (
      <Alert
        icon={<WarningAmberOutlined fontSize="inherit" />}
        severity="warning"
      >
        Delivery didn't received
      </Alert>
    );
  }
  if (order.cancelled) {
    return (
      <Alert icon={<CancelOutlined fontSize="inherit" />} severity="error">
        Cancelled
      </Alert>
    );
  }
  if (order.dispatched) {
    return (
      <>
        <button
          style={{
            padding: "8px 16px",
            backgroundColor: "#007BFF",
            color: "white",
            border: "none",
            borderRadius: "5px",
            fontSize: "14px",
            cursor: "pointer",
            transition: "background-color 0.3s",
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#007BFF")}
          onClick={handleTrackOrder}
        >
          Track
        </button>
      </>
    );
  }

  return (
    <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
      Not dispatched yet.
    </Alert>
  );
};

export default DeliveryStatus;
