import React, { useState } from "react";
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Button,
} from "@mui/material";
import {
    getTrackingByAWBXpressBees,
    getTrackingByAWBDelhivery,
} from "../actions/trackingLambda";

const TrackFulfillmentComponent = ({
    order,
    setShowDialog,
    setSelectedOrder,
    isMobile,
}) => {
    const [trackingError, setTrackingError] = useState("");
    const [loadingTracking, setLoadingTracking] = useState(false);

    const handleTrack = async (awb_code) => {
        setLoadingTracking(true);
        let tracking_response;
        let courierName;
        // If awb starts with 29 try delhivery, else expressbees
        if (awb_code.toString().startsWith("29")) {
            const response = await getTrackingByAWBDelhivery(awb_code);
            if (response.status !== 200) {
                setTrackingError("Tracking Details not updated yet.");
                return;
            }
            const shipments = response.data[0];
            const scans = shipments.Shipment.Scans;
            tracking_response = {
                AWBNo: awb_code,
                OrderNo: shipments.Shipment.ReferenceNo,
                ShipmentSummary: scans.map((i) => i.ScanDetail).reverse(),
            };
            courierName = "Delhivery";
            console.log("Delhivery");
            console.log(tracking_response);
        } else {
            const response = await getTrackingByAWBXpressBees(awb_code);
            if (response.status !== 200) {
                setTrackingError("Tracking Details not updated yet.");
                return;
            }
            tracking_response = response.data[0];
            courierName = "XpressBees";
            console.log("Xpressbees");
            console.log(tracking_response);
        }

        if (tracking_response.ShipmentSummary.length > 0) {
            const trackingDetails = {
                ...tracking_response,
                courierName,
            };
            setSelectedOrder(trackingDetails);
            setShowDialog(true);
        } else {
            setSelectedOrder(null);
            setShowDialog(false);
        }
        setLoadingTracking(false);
        setTrackingError("");
    };

    const tableContainerStyle = {
        marginTop: "20px",
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "0px",
    };

    const orderDetailsContainerStyle = {
        backgroundColor: "black",
        color: "white",
        borderRadius: "0px",
    };

    const trackingDetailsContainerStyle = {
        marginTop: "20px",
        borderRadius: "8px",
        overflow: isMobile ? "scroll" : "hidden",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    };

    const smallerText = {
        fontSize: "0.30rem",
        fontWeight: "bold",
    };

    const largeText = {
        fontSize: "1rem",
    };

    const h6Style = {
        fontSize: "1rem",
        fontWeight: "700",
    };

    return (
        <div>
            <TableContainer component={Paper} style={tableContainerStyle}>
                <Table>
                    <TableBody>
                        <TableRow style={orderDetailsContainerStyle}>
                            <TableCell
                                style={{
                                    ...largeText,
                                    backgroundColor: "#313132",
                                    color: "#e8ef4a",
                                    fontWeight: "900",
                                }}
                            >
                                {`Order ID: #${
                                    order.original_order_id
                                } (${new Date(order.order_date)})`}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer
                component={Paper}
                style={trackingDetailsContainerStyle}
            >
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell style={smallerText}>
                                <Typography variant="h6" style={h6Style}>
                                    Fulfillment Id:
                                </Typography>
                                <Typography>{order.fulfillment_id}</Typography>
                            </TableCell>
                            <TableCell style={smallerText}>
                                <Typography variant="h6" style={h6Style}>
                                    Status:
                                </Typography>
                                <Typography>{order.status}</Typography>
                            </TableCell>
                            <TableCell style={smallerText}>
                                <Typography variant="h6" style={h6Style}>
                                    Mode:
                                </Typography>
                                <Typography>{order.payment_method}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6" style={h6Style}>
                                    Processed Date:
                                </Typography>
                                <Typography>
                                    {new Date(
                                        order.processed_at
                                    ).toLocaleDateString()}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={smallerText} colSpan={3}>
                                <Typography variant="h6" style={h6Style}>
                                    Product Details:
                                </Typography>
                                <Typography
                                    variant="body2"
                                    style={{
                                        textTransform: "lowercase",
                                    }}
                                >
                                    Items dispatched: {order.order_items.length}
                                </Typography>
                            </TableCell>

                            <TableCell></TableCell>

                            <TableCell></TableCell>

                            <TableCell></TableCell>
                        </TableRow>
                        {order.order_items.map((item) => {
                            const imageURL =
                                item.url ||
                                "https://psediting.websites.co.in/obaju-turquoise/img/product-placeholder.png";
                            return (
                                <TableRow key={item.id}>
                                    <TableCell style={smallerText}>
                                        <Typography
                                            variant="h6"
                                            style={h6Style}
                                        ></Typography>
                                        <img
                                            src={imageURL}
                                            alt="Product"
                                            style={{
                                                width: "70px",
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell style={smallerText} colSpan={3}>
                                        <Typography
                                            variant="h6"
                                            style={h6Style}
                                        >
                                            Product Name:
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textTransform: "lowercase",
                                            }}
                                        >
                                            {item.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={smallerText} colSpan={3}>
                                        <Typography
                                            variant="h6"
                                            style={h6Style}
                                        >
                                            Size:
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textTransform: "lowercase",
                                            }}
                                        >
                                            {item.variant_title}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={smallerText} colSpan={3}>
                                        <Typography
                                            variant="h6"
                                            style={h6Style}
                                        >
                                            Quantity:
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textTransform: "lowercase",
                                            }}
                                        >
                                            {item.units}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}

                        {order.awb_code && (
                            <TableRow>
                                <TableCell style={smallerText}>
                                    <Typography variant="h6" style={h6Style}>
                                        Tracking Number:
                                    </Typography>
                                    <Typography>{order.awb_code}</Typography>
                                </TableCell>
                                <TableCell style={smallerText}>
                                    <Button
                                        variant="outlined"
                                        style={{
                                            background: loadingTracking
                                                ? "#a88989"
                                                : "#313132",
                                            color: "white",
                                        }}
                                        onClick={() =>
                                            handleTrack(order.awb_code)
                                        }
                                        disabled={loadingTracking}
                                    >
                                        Track
                                    </Button>
                                </TableCell>
                                <TableCell>{trackingError}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default TrackFulfillmentComponent;
