import React, { useState } from "react";
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";

const SizeSelector = ({ product, onChange, exchangeChoice }) => {
  const [size, setSize] = useState(
    exchangeChoice !== "1" ? product.variant_title : ""
  );

  const handleChange = (e) => {
    setSize(e.target.value);
    onChange(e);
  };

  return (
    <FormControl>
      <Select
        style={{
          display: "block",
          width: "60px",
        }}
        value={size}
        onChange={handleChange}
        label="New Size"
        placeholder="Select Replacement Size"
      >
        {product.sizes
          .filter(
            (s) =>
              exchangeChoice !== "1" ||
              (exchangeChoice === "1" && s !== product.variant_title)
          )
          .map((i) => (
            <MenuItem key={i} value={i}>
              {i}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>New Size</FormHelperText>
    </FormControl>
  );
};

export default SizeSelector;
