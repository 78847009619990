import { Box, Button } from "@mui/material";
import React from "react";

const SuccessModal = ({order}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "60%",
    bgcolor: "#f9f4f5",
    boxShadow: 24,
    p: 4,
    fontFamily: "Helvetica sans-serif",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    borderRadius:"10px",

  };
  return <Box sx={style}>
      <img style ={{objectFit:"contain", width: "180px"}}src="lblogo.jpeg" alt=""></img>
      <h3>Your request has been submitted.</h3>
      <h3>Note: Your pickup will be initiated once your request is approved </h3>
      <a href="https://littleboxindia.com/">
        <Button
                variant="contained"
                style={{ backgroundColor: "#212B36" }}
                >Continue Shopping</Button>
      </a>
      
      

  </Box>;
  };

export default SuccessModal;
