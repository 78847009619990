import React from "react";
import { MenuItem, Select } from "@mui/material";

const Selector = ({ choice, setChoice, dropdownChoice, selected }) => {
  const handleSelect = (e) => setChoice(e.target.value);
  const options =
    dropdownChoice === "Return Exchange"
      ? [
          {
            label: "Exchange",
            value: "2",
          },
        ]
      : [
          {
            label: "None",
            value: "0",
          },
          {
            label: "Size-Exchange",
            value: "1",
          },
          {
            label: "Damaged/Wrong Product",
            value: "2",
          },

          {
            label: "Both",
            value: "3",
          },
        ];
  console.log("selected", selected);
  console.log("border ", dropdownChoice !== "Return Exchange");
  return (
    <div className="reason-slct">
      {dropdownChoice === "Return Exchange" ? (
        <div
          style={{ padding: "20px 0 0px 0", width: "95%", paddingLeft: "14px" }}
        >
          *Select Exchange Reason
        </div>
      ) : (
        ""
      )}

      <Select
        className="reason-selector"
        sx={{
          border:
            dropdownChoice !== "Return Exchange" && selected
              ? "1px solid red"
              : "",
        }}
        onChange={handleSelect}
        id="select-reason"
        label="Select Reason"
        value={choice}
      >
        {options.map((o) => (
          <MenuItem value={o.value} key={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default Selector;
