export const getAllTicketsOfCustomer = async (customerId) => {
  let res;
  try {
    const response = await fetch(
      `https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/tickets/getticket`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ customerId }),
      }
    );

    const data = await response.json();
    if (data.statusCode === 200) {
      res = {
        status: 200,
        data: data.data,
      };
    } else {
      res = {
        status: 500,
      };
    }
  } catch (err) {
    res = { status: 500 };
  }

  return res;
};

export const getSingleTicketOfCustomer = async (ticketId) => {
  let res;
  try {
    const response = await fetch(
      `https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/tickets/getsingleticket`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ ticketId }),
      }
    );

    const data = await response.json();
    if (data.statusCode === 200) {
      res = {
        status: 200,
        data: data.data,
      };
    } else {
      res = {
        status: 500,
      };
    }
  } catch (err) {
    res = { status: 500 };
  }

  return res;
};

export const sendMessage = async (ticketId, message, files) => {
  let res;
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/tickets/sendmessage";
  try {
    const payload = {
      ticketId: ticketId,
      message: {
        sendedBy: "customer",
        time: new Date().toLocaleString(),
        files,
        message,
      },
    };
    const response = await fetch(URL, {
      method: "POST",

      body: JSON.stringify(payload),
    });
    const data = await response.json();
    console.log("data", data);
    if (data.statusCode === 200) {
      res = {
        status: 200,
        data: data.data,
      };
    } else {
      res = {
        status: 500,
      };
    }
  } catch (err) {
    console.log(err);
    res = { status: 500 };
  }
  return res;
};

const sendTicketEmail = async (email, ticketId, customerId) => {
  try {
    const response = await fetch(
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/tickets/sessendticketupdate",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          status: "ticket_created",
          email,
          ticketId,
          customerId,
        }),
      }
    );

    const data = await response.json();
    console.log("data", data);
    // if (data.statusCode === 400) {
    //   return { data: "Request Already Submitted", success: false };
    // } else if (data.statusCode === 500) {
    //   return {
    //     data: "Error Submitting, Please Try Again",
    //     success: false,
    //   };
    // }
    // res = { data, success: true };
  } catch (err) {
    console.log("");
  }
};

export const checkTicketAvailability = async (customerId, orderId) => {
  // True means can create ticket
  let res;
  try {
    const response = await fetch(
      `https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/tickets/checkticketavailability`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ customerId, orderId }),
      }
    );

    const data = await response.json();

    if (data.statusCode === 200) {
      res = data.isTicketAvailable;
    } else {
      res = true;
    }
  } catch (err) {
    res = true;
  }

  return res;
};

export const createTicket = async (payload, email) => {
  let res;
  let fetching = true;
  while (fetching) {
    try {
      const isTicketAvailable = await checkTicketAvailability(
        payload.customer_id,
        payload.order_id
      );
      // true means can create ticket
      if (!isTicketAvailable) {
        fetching = false;
        res = {
          data: "Cannot create more than one ticket for an order, please continue the conversation over the previous ticket for this order",
          success: false,
        };
        break;
      }
      const response = await fetch(
        `https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/tickets/createticket`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      console.log("data", data);
      if (data.statusCode === 500) {
        fetching = false;
        res = {
          data: "Error Submitting, Please Try Again",
          success: false,
        };
      } else if (data.statusCode === 200) {
        fetching = false;
        res = {
          data: "Ticket Created Successfully",
          success: true,
          ticketId: data.data.original_order_id,
        };
        if (email) {
          await sendTicketEmail(
            email,
            data.data.original_order_id,
            data.data.customer_id
          );
        }
      }
    } catch (err) {
      fetching = false;
      res = { data: "Server Error, Please Try Again", success: false };
    }
  }

  return res;
};

export const sendTicketUpdateToShopify = async (customerId) => {
  try {
    const response = await fetch(
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/tickets/customermetafield",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ customerId, value: false }),
      }
    );

    const data = await response.json();
    console.log("data", data);
  } catch (err) {
    console.log("");
  }
};

export const closeTicket = async (ticket) => {
  let res;
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/tickets/changeticketstatus";

  try {
    const payload = {
      ticketId: ticket.original_order_id,
      ticketNumber: ticket.fulfillment_id,
      status: "closed",
      closedBy: "customer",
      customerId: ticket.customer_id,
      executive_id: ticket.executive_id
        ? ticket.executive_id.split("#")[0]
        : "None",
    };

    const response = await fetch(URL, {
      method: "POST",

      body: JSON.stringify(payload),
    });
    const data = await response.json();

    if (data.statusCode === 200) {
      res = {
        status: 200,
        data: data.data,
      };
    } else {
      res = {
        status: 500,
      };
    }
  } catch (err) {
    console.log(err);
    res = { status: 500 };
  }
  return res;
};

export const reopenTicket = async (ticket) => {
  let res;
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/tickets/changeticketstatus";

  try {
    const payload = {
      ticketId: ticket.original_order_id,
      ticketNumber: ticket.fulfillment_id,
      status: "reopen",
      customerId: ticket.customer_id,
      executive_id: ticket.executive_id
        ? ticket.executive_id.split("#")[0]
        : "None",
    };

    const response = await fetch(URL, {
      method: "POST",

      body: JSON.stringify(payload),
    });
    const data = await response.json();

    if (data.statusCode === 200) {
      res = {
        status: 200,
        data: data.data,
      };
    } else {
      res = {
        status: 500,
      };
    }
  } catch (err) {
    console.log(err);
    res = { status: 500 };
  }
  return res;
};
