import { Box } from "@mui/system";
import React from "react";

const Loader = () => {
  return (
    <Box className="loader">
      <img
        src="https://cdn.shopify.com/s/files/1/0604/6957/5837/files/provicali_new_logo_2_1_260x.png?v=1661172939"
        alt=""
      />
      <h1>Please Wait...</h1>
    </Box>
  );
};

export default Loader;
