export const uploadToDrive = async (files) => {
  const file_array = files;
  const promise = file_array.map((file) => {
    return fetch(
      "https://script.google.com/macros/s/AKfycbwp81ZEBoRMSp_GSdibRwqPOLnTqZF_3u-E4UR3jmh2Fo4Hz-0o2wmQ21UqiQw6vuQ8/exec",
      { method: "POST", body: JSON.stringify(file) }
    );
  });
  try {
    const response = await Promise.all(promise).then(async (res) =>
      Promise.all(res.map(async (data) => await data.json()))
    );
    console.log("response", response);
    return {
      status: 200,
      data: response,
    };
  } catch (error) {
    console.log("error", error);
    return {
      status: 500,
      error: error,
    };
  }
};
