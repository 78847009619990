import React from "react";
import {
    Twitter,
    Facebook,
    YouTube,
    Instagram,
    Pinterest,
} from "@mui/icons-material";
import { Stack, Link } from "@mui/material";

const Footer = () => {
    return (
        <footer className="footer">
            <div>
                <ul className="list-none">
                    <li>
                        <a href="https://littleboxindia.com/collections/new-arrivals">
                            New Arrivals
                        </a>
                    </li>
                    <li>
                        <a href="https://littleboxindia.com/collections/tops">
                            Tops
                        </a>
                    </li>
                    <li>
                        <a href="https://littleboxindia.com/collections/flats">
                            Flats
                        </a>
                    </li>
                    <li>
                        <a href="https://littleboxindia.com/collections/women-t-shirts">
                            T-Shirts
                        </a>
                    </li>
                    <li>
                        <a href="https://littleboxindia.com/collections/trouser">
                            Trousers
                        </a>
                    </li>
                    <li>
                        <a href="https://littleboxindia.com/collections/womens-heels">
                            Heels
                        </a>
                    </li>
                </ul>
            </div>
            <div>
                <ul className="list-none">
                    <li>
                        <a href="https://littleboxindia.com/collections/oxfords">
                            Oxfords
                        </a>
                    </li>
                    <li>
                        <a href="https://littleboxindia.com/collections/womens-platforms">
                            Platforms & Flatforms
                        </a>
                    </li>
                    <li>
                        <a href="https://littleboxindia.com/collections/mules-loafers">
                            Mules & Loafers
                        </a>
                    </li>
                    <li>
                        <a href="https://littleboxindia.com/collections/matching-sets">
                            Matching Sets
                        </a>
                    </li>
                    <li>
                        <a href="https://littleboxindia.com/collections/best-selling">
                            Best Selling
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://goodtribe.io/">
                            IT Partners
                        </a>
                    </li>
                </ul>
            </div>
            <div className="address">
                <p>© LittleBoxIndia 2022</p>
                <p>
                    Address: Vinayak Path, Lakhimi Nagar,<br></br> Hatigaon, Guwahati
                    <br></br>
                    Assam, 781006
                </p>
                <Stack direction={"row"} gap={1}>
                    <Link
                        href="https://twitter.com/LittleboxIndia?ref=m5MMr0kc"
                        color="#FFFFFF"
                    >
                        <Twitter />
                    </Link>
                    <Link
                        href="https://www.instagram.com/shoplittlebox_india/?ref=m5MMr0kc"
                        color="#FFFFFF"
                    >
                        <Instagram />
                    </Link>
                    <Link
                        href="https://www.facebook.com/littleboxIndiaofficial/?ref=m5MMr0kc"
                        color="#FFFFFF"
                    >
                        <Facebook />
                    </Link>
                    <Link
                        href="https://www.youtube.com/channel/UCqc8hdghhIsI7nP7oj9wTXw?ref=m5MMr0kc"
                        color="#FFFFFF"
                    >
                        <YouTube />
                    </Link>
                    <Link
                        href="https://in.pinterest.com/shoplittlebox_india/_saved/?ref=m5MMr0kc"
                        color="#FFFFFF"
                    >
                        <Pinterest />
                    </Link>
                </Stack>
            </div>
        </footer>
    );
};

export default Footer;
