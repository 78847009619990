import React from "react";
import { Skeleton} from "@mui/material";
import { Box, Stack } from "@mui/system";

const SkeletonProgress = () => {
  return (
    <div className="page">
      <div style={{ margin: "30px 0 0 0" }}>
        <div className="update-phone">
          <Skeleton variant="rounded" width="160px" height="20px" />

          <form>
            <Skeleton variant="rounded" width="200px" height="20px" />
          </form>
        </div>
        <Box position={"relative"}>
          <Skeleton variant="rounded" width="100%" height={500} />
          <Stack
            position="absolute"
            width={"100%"}
            gap={4}
            sx={{ top: 0, padding: 3 }}
          >
            <Skeleton variant="rounded" width={"30%"} height={20} />
            <Skeleton variant="rounded" width={"20%"} height={20} />
            <Skeleton variant="rounded" width={"10%"} height={20} />
            <Skeleton variant="rounded" width={"70%"} height={100} />
            <Skeleton variant="rounded" width={"70%"} height={100} />
            <Skeleton variant="rounded" width={"40%"} height={30} />
          </Stack>
        </Box>
        {/* <Container className="order-container">
        <Skeleton variant="rounded" width="100%" height={500} />
    </Container> */}
      </div>
    </div>
  );
};

export default SkeletonProgress;
