export const forwardOrderStatus = {
  DRC: "Data Received",
  OFP: "Out For Pickup",
  PND: "Pickup Not Done",
  PKD: "Picked",
  IT: "IT",
  RAD: "Reached at Destination",
  OFD: "Out for Delivery",
  DLVD: "Delivered",
  UD: "Undelivered",
  RTON: "RTO Notified",
  RTO: "RTO",
  "RTO-IT": "RTO-IT",
  RAO: "Reached At Origin",
  "RTO-OFD": "RTO Out For Delivery",
  RTD: "RTO Delivered",
  RTU: "RTO Undelivered",
  PUD: "Pickup done",
  STD: "ShipmentTransit",
  STG: "Shortage",
  UD: "Undelivered",
  DL: "Delivered",
  RT: "Returned",
};
