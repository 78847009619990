import { CircularProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const Toast = ({ message }) => {
  return (
    <Stack
      zIndex={500}
      gap={2}
      direction={"row"}
      margin={2.5}
      padding={2}
      height={35}
      width={"20vw"}
      sx={{
        position: "fixed",
        bottom: 0,
        right: 0,
        background: "#323232",
        borderRadius: 1,
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {/* <h4 style={{ fontColor: "#9B9B9B" }}>uploading</h4> */}
      <Typography color={"#FFFFFF"} variant="p" component="h3">
        {message}
      </Typography>
      <CircularProgress />
    </Stack>
  );
};

export default Toast;
