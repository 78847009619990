import React, { useState } from "react";
import { Box, Button, Stack, TextField } from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";

const AddImage = ({ setImages, handleClose }) => {
  const [files, setFiles] = useState([]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "60%",
    bgcolor: "#f9f4f5",
    boxShadow: 24,
    p: 4,
    fontFamily: "Helvetica sans-serif",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    textDecoration: "none",
    borderRadius: "10px",
  };
  const handleChange = (fileArray) => {
    const selectedFilesLength = files.length;
    fileArray.forEach((fileItem, index) => {
      const reader = new FileReader();
      reader.readAsDataURL(fileItem);
      reader.onload = function () {
        const rawLog = reader.result.split(",")[1];
        const dataSend = {
          id: index + selectedFilesLength,
          size: fileItem.size,
          dataReq: {
            data: rawLog,
            name: fileItem.name,
            type: fileItem.type,
          },
          fname: "uploadFilesToGoogleDrive",
        };
        setFiles((f) => [...f, dataSend]);
      };
    });
  };

  const handleDeleteFiles = (id) => {
    const newFileArray = files
      .filter((f) => f.id !== id)
      .map((file, index) => ({ ...file, id: index }));
    setFiles(newFileArray);
  };
  return (
    <Box sx={style}>
      <Stack direction={"row"} alignItems="center" gap={2}>
        <TextField
          required
          variant="outlined"
          type="file"
          id="file"
          label="Upload Images"
          onChange={(e) => handleChange([...e.target.files])}
          inputProps={{
            multiple: true,
            accept: "image/jpeg",
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button variant="contained" color="error" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setImages(files);
            handleClose();
          }}
        >
          Add
        </Button>
      </Stack>
      <br />
      {files.map((f) => (
        <Stack marginTop={1.2} direction={"row"} gap={1} alignItems={"center"}>
          <h4 style={{ padding: 0, margin: 0 }}>{f.dataReq.name} </h4>{" "}
          <ClearIcon
            onClick={() => handleDeleteFiles(f.id)}
            sx={{
              "&:hover": {
                color: "red",
                cursor: "pointer",
                transform: "scale(1.3)",
              },
            }}
          />
        </Stack>
      ))}
    </Box>
  );
};

export default AddImage;
