import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  Button,
  Checkbox,
  Container,
  TextField,
  InputAdornment,
  IconButton,
  Snackbar,
  Modal,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import ClearIcon from "@mui/icons-material/Clear";
import SkeletonProgress from "./SkeletonProgress";
import EditIcon from "@mui/icons-material/Edit";

import CloseIcon from "@mui/icons-material/Close";
import Selector from "./Selector";
import EditShipping from "./EditShipping";
import {
  submitData,
  updateCustomerMobileNumber,
  checkEmail,
} from "../actions/shopifyLambda";
import { uploadToDrive } from "../actions/googledrive";
import ProductDisplay from "./ProductDisplay";
import { Stack } from "@mui/system";
import Toast from "./Toast";
import SuccessModal from "./SuccessModal";

const OrderDisplay = ({ order, loading, email, exchangeHistory }) => {
  const [choice] = useState("2");
  const { customer, line_items: products } = order;
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [editEmail, setEditEmail] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [customerMail, setCustomerMail] = useState(email);
  const [mailStatus, setMailStatus] = useState(204);
  const [editPhone, setEditPhone] = useState(false);
  const [customerMobile, setCustomerMobile] = useState(
    order.shipping_address.phone
  );
  const [files, setFiles] = useState([]);
  const [reason, setReason] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [exchangeChoice, setExchangeChoice] = useState("1");
  const [notSelectedExchange, setNotSelectedExchange] = useState(false);
  const [notSelectedImages, setNotSelectedImages] = useState(false);
  const [notSelectedReason, setNotSelectedReason] = useState(false);
  // const [notSelectedExchangeAction, setNotSelectedExchangeAction] =
  //     useState(false);
  const [addressEditable, setAddressEditable] = useState(false);
  // const [exchangeAction, setExchangeAction] = useState("");
  const matches = useMediaQuery("(min-width:700px)");

  const handleClose = () => setOpen(false);
  const handleModalClose = () => setModalOpen(false);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const selectProductHandler = (e, p) => {
    if (e.target.checked) {
      if (exchangeChoice !== "1") {
        p.selectedSize = p.variant_title;
      }
      setSelectedProducts((i) => [...i, p]);
    } else {
      const filteredProducts = selectedProducts.filter(
        (item) => item.id !== p.id
      );
      setSelectedProducts(filteredProducts);
    }
  };
  const checkSelectedSize = () => {
    let i = 0;
    selectedProducts.forEach((p) => {
      if (!p.selectedSize) {
        i = i + 1;
      }
    });
    if (i > 0) {
      return false;
    } else return true;
  };
  const handleChange = (fileArray) => {
    const selectedFilesLength = files.length;
    fileArray.forEach((fileItem, index) => {
      const reader = new FileReader();
      reader.readAsDataURL(fileItem);
      reader.onload = function () {
        const rawLog = reader.result.split(",")[1];
        const dataSend = {
          id: index + selectedFilesLength,
          size: fileItem.size,
          dataReq: {
            data: rawLog,
            name: fileItem.name,
            type: fileItem.type,
          },
          fname: "uploadFilesToGoogleDrive",
        };
        setFiles((f) => [...f, dataSend]);
      };
    });
  };

  const handlePhoneChange = async (e) => {
    e.preventDefault();
    const number = document.getElementById("phone").value;

    setCustomerMobile(number);
    // Call a lambda to update customer mobile number
    await updateCustomerMobileNumber(number, customer);

    setEditPhone(false);
  };

  const handleEmailChange = async (e) => {
    e.preventDefault();
    setEmailInvalid(false);

    const email = document.getElementById("email").value;

    const data = await checkEmail(email);
    if (data === 404) {
      setEmailInvalid(true);
    }
    if (data === 500) {
      setMailStatus(500);
    }
    setCustomerMail(email);

    setEditEmail(false);
  };

  const handleProductSelected = (e, id) => {
    const updatedProducts = selectedProducts.map((p) => {
      if (p.id === id) {
        p.selectedSize = e.target.value;
      }
      return p;
    });
    setSelectedProducts(updatedProducts);
  };
  console.log("selected products", selectedProducts);
  const handleSubmit = async () => {
    //check email
    if (emailInvalid) {
      setSnackBarMessage("Invalid Email, Enter Valid Email");
      setOpen(true);
      return;
    }
    if (!customerMail) {
      setSnackBarMessage("Email is Mandatory");
      setOpen(true);
      return;
    }
    // CHECK IF NO PRODUCT SELECTED
    if (selectedProducts.length === 0) {
      setSnackBarMessage(
        "No products selected. Please select at least 1 product"
      );
      setOpen(true);
      return;
    }
    setNotSelectedExchange(false);
    setNotSelectedImages(false);
    setNotSelectedReason(false);
    // uploading files
    let payloadFiles = [];

    if (files.length === 0) {
      setSnackBarMessage("Image is mandatory");
      setOpen(true);
      setNotSelectedImages(true);
      return;
    }
    if (choice === "2" && exchangeChoice === "0") {
      setSnackBarMessage("Please select exchange reason");
      setOpen(true);
      setNotSelectedExchange(true);
      return;
    }
    if (!reason) {
      setSnackBarMessage("Please describe return reason");
      setOpen(true);
      setNotSelectedReason(true);
      return;
    }
    const sizeSelected = checkSelectedSize();
    if (!sizeSelected) {
      setSnackBarMessage("Please select Size");
      setOpen(true);
      return;
    }
    setToastMessage("Uploading Images");
    setSubmitLoading(true);
    const uploadResponse = await uploadToDrive(files);
    if (uploadResponse.status === 200) {
      payloadFiles.push(...uploadResponse.data);
    } else {
      setSubmitLoading(false);
      setSnackBarMessage("Failed to upload files, try again");
      setOpen(true);
      return;
    }
    setToastMessage("Submitting Request");

    const payload = {
      fulfillment_id:
        "R_" + String(order.order_number) + `.${exchangeHistory.length + 1}`,
      customerId: customer.id,
      alternatePhone: customerMobile,
      address: order.shipping_address,
      orderId: order.id,
      orderNumber:
        String(order.order_number) + `.${exchangeHistory.length + 1}`,
      products: selectedProducts.map((p) => ({
        id: p.id,
        originalSize: p.variant_title,
        size: p.selectedSize,
        sku: p.sku,
        mrp: p.price,
      })),
      type: choice === "2" ? "Exchange" : "Return",
      exchangeReason:
        exchangeChoice === "1"
          ? "Size-Exchange"
          : exchangeChoice === "2"
          ? "Damaged Product"
          : "Both",
      files: payloadFiles,
      tags: order.tags,
      email: customerMail,
      mailCheckStatus: mailStatus,
      reason,
      exchangeAction: "Replacement",
    };

    const newOrderItems = {
      fulfillment_id: payload.fulfillment_id,
      line_items: selectedProducts.map((p) => ({
        sku: p.sku,
        size: p.variant_title,
      })),
    };
    const response = await submitData(
      payload,
      customerMail,
      order.id,
      newOrderItems,
      exchangeHistory
    );

    if (response.success === true) {
      setModalOpen(true);
    } else {
      setSubmitLoading(false);
      setSnackBarMessage(response.data);
      setOpen(true);
    }
    setSubmitLoading(false);
  };

  const handleDeleteFiles = (id) => {
    const newFileArray = files
      .filter((f) => f.id !== id)
      .map((file, index) => ({ ...file, id: index }));
    setFiles(newFileArray);
  };

  return (
    <>
      {loading ? (
        <SkeletonProgress />
      ) : (
        <div className="main-body-cnt" style={{ margin: "30px -30px 50px 0" }}>
          <div className="product-row">
            <div className="update-phone">
              <h4>Phone:</h4>
              <form onSubmit={handlePhoneChange}>
                <TextField
                  className="phone-cnt"
                  required
                  disabled={!editPhone}
                  id="phone"
                  defaultValue={customerMobile || "Not available"}
                  variant="outlined"
                  onBlur={handlePhoneChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle edit field"
                          onClick={() => setEditPhone(!editPhone)}
                          edge="end"
                        >
                          {editPhone ? "" : <EditIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </div>
            <Button
              className="edit-btn"
              variant="outlined"
              onClick={() => setAddressEditable(!addressEditable)}
            >
              {addressEditable ? "Discard Change" : "Edit Shipping Address"}
            </Button>
          </div>
          <div className="update-email">
            <h4>Email:</h4>&nbsp;&nbsp;&nbsp;
            <form onSubmit={handleEmailChange}>
              <TextField
                className="edit-email"
                required
                disabled={!editEmail}
                id="email"
                defaultValue={customerMail || "Not available"}
                variant="outlined"
                onBlur={handleEmailChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle edit field"
                        onClick={() => {
                          setEditEmail(!editEmail);
                          setEmailInvalid(false);
                        }}
                        edge="end"
                      >
                        {editEmail ? "" : <EditIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </form>
            <p style={{ marginLeft: "10px" }}>
              *Email is mandatory for returns/exchanges, so that we can notify
              you when your request is accepted.
            </p>
            {emailInvalid ? (
              <h4 style={{ color: "red", margin: 0 }}>Invalid Email</h4>
            ) : (
              ""
            )}
          </div>
          {addressEditable && (
            <EditShipping order={order} close={setAddressEditable} />
          )}

          <div className="exchange-reason">
            <h4>Reason:</h4>
            {choice === "2" ? (
              <Selector
                choice={exchangeChoice}
                setChoice={setExchangeChoice}
                selected={notSelectedExchange}
              />
            ) : (
              ""
            )}
          </div>

          <Container className="order-container">
            {/* <Selector
                            choice={choice}
                            setChoice={setChoice}
                            dropdownChoice={"Exchange"}
                        /> */}

            <div className="select-product">
              <h4>Select Products:</h4>
              <ul>
                {products.map((p) => (
                  <div key={p.id} className="product-row">
                    <Checkbox onChange={(e) => selectProductHandler(e, p)} />
                    <ProductDisplay
                      onChange={(e) => handleProductSelected(e, p.id)}
                      product={p}
                      exchangeChoice={exchangeChoice}
                      isExchange={choice === "2"}
                      isSelected={
                        selectedProducts.filter((i) => i.id === p.id).length > 0
                      }
                    />
                  </div>
                ))}
              </ul>
            </div>
            <div>
              {matches ? (
                <Stack
                  direction={"row"}
                  gap={2}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    required
                    variant="outlined"
                    type="file"
                    id="file"
                    label="Upload Images"
                    error={notSelectedImages}
                    onChange={(e) => handleChange([...e.target.files])}
                    inputProps={{
                      multiple: true,
                      accept: "image/jpeg",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <p style={{ color: "gray" }}>
                    <span
                      style={{
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      *
                    </span>
                    Kindly upload clear images of the product for verification.
                    <span style={{ fontWeight: 800 }}>
                      Also ensure that the product tags are visible in the image
                      uploaded for the exchange to get approved
                    </span>
                  </p>
                </Stack>
              ) : (
                <Stack direction={"column"} gap={2}>
                  <p style={{ color: "gray" }}>
                    <span
                      style={{
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      *
                    </span>
                    Kindly upload clear images of the product for verification.
                    <span style={{ fontWeight: 800 }}>
                      Also ensure that the product tags are visible in the image
                      uploaded for the exchange to get approved
                    </span>
                  </p>
                  <TextField
                    required
                    variant="outlined"
                    type="file"
                    id="file"
                    label="Upload Images"
                    error={notSelectedImages}
                    onChange={(e) => handleChange([...e.target.files])}
                    inputProps={{
                      multiple: true,
                      accept: "image/jpeg",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Stack>
              )}
            </div>
            <br />
            {files.map((f) => (
              <Stack
                marginTop={1.2}
                direction={"row"}
                gap={1}
                alignItems={"center"}
              >
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: "12px",
                    wordBreak: "break-word",
                  }}
                >
                  {f.dataReq.name}{" "}
                </p>{" "}
                <ClearIcon
                  onClick={() => handleDeleteFiles(f.id)}
                  sx={{
                    "&:hover": {
                      color: "red",
                      cursor: "pointer",
                      transform: "scale(1.3)",
                    },
                  }}
                />
              </Stack>
            ))}

            {/* <h4>Select Replacement or Voucher</h4> */}

            {/* <Box sx={{ maxWidth: 337, paddingTop: "20px" }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    Replacement or Voucher
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={exchangeAction}
                                    label="Replacement or Voucher"
                                    onChange={(e) =>
                                        setExchangeAction(e.target.value)
                                    }
                                >
                                    <MenuItem value={"Replacement"}>
                                        Replacement
                                    </MenuItem>
                                    <MenuItem value={"Giftcard"}>
                                        Giftcard/Voucher
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box> */}

            <div>
              <TextField
                required
                id="filled-multiline-static"
                label="Exchange Reason"
                onChange={(e) => setReason(e.target.value)}
                error={notSelectedReason}
                multiline
                rows={3}
                variant="standard"
                style={{
                  margin: "20px 20px 20px 0px",
                  width: "100%",
                }}
              />
            </div>
            <Stack direction={"row"} gap={2} alignItems={"center"}>
              <Button
                disabled={submitLoading}
                variant="contained"
                style={{ backgroundColor: "#212B36" }}
                onClick={handleSubmit}
              >
                SUBMIT
              </Button>
            </Stack>
          </Container>
          {submitLoading ? <Toast message={toastMessage} /> : ""}
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={snackbarMessage}
            action={action}
          />
          <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <SuccessModal order={order} />
          </Modal>
        </div>
      )}
    </>
  );
};

export default OrderDisplay;
