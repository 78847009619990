import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import ChatModule from "./pages/ChatModule";
import TicketIssue from "./pages/TicketIssue";
import MyTickets from "./pages/MyTickets";
import { Link, Routes, Route, Outlet } from "react-router-dom";
import TrackOrderPage from "./pages/TrackOrder";
import OrderList from "./pages/OrderList";

const Layout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

const App = () => {
  return (
    <>
      {/* <HomePage /> */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="newticket" element={<TicketIssue />} />
          <Route path="mytickets" element={<MyTickets />} />
          <Route path="ticket" element={<ChatModule />} />
          <Route path="trackyourorder" element={<TrackOrderPage />} />
          <Route path="orderlist" element={<OrderList />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
      {/* <TicketIssue />
       
      <MyTickets/>

      <ChatModule /> */}
    </>
  );
};

export default App;
