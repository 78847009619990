import React, { useState } from "react";
import { getCustomerOrders } from "../actions/orderListLambda";

import DeliveryStatus from "../components/DeliveryStatus";
import { CircularProgress, Skeleton } from "@mui/material";

const styles = {
  orderList: {
    textAlign: "center",
    marginTop: "50px",
    fontFamily: "Arial, sans-serif",
    minHeight: "45vh",
  },
  mainHeader: { fontWeight: "bold", color: "#333", fontSize: "28px" },
  inputField: {
    padding: "12px",
    width: "320px",
    margin: "20px 0",
    borderRadius: "8px",
    border: "1px solid #ccc",
    fontSize: "16px",
  },
  fetchButton: {
    padding: "12px 24px",
    backgroundColor: "#007BFF",
    color: "white",
    border: "none",
    borderRadius: "8px",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  orderItemList: {
    marginTop: "40px",
    textAlign: "left",
    maxWidth: "600px",
    margin: "40px auto",
  },
  orderListHeading: {
    color: "#007BFF",
    marginBottom: "20px",
    textAlign: "center",
  },
  orderItem: {
    padding: "15px",
    margin: "10px 0",
    borderRadius: "8px",
    border: "1px solid #ddd",
    backgroundColor: "#f9f9f9",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  },
};

const OrderList = () => {
  const [identifier, setIdentifier] = useState("");
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [message, setMessage] = useState(null);

  const handleTrackOrder = async () => {
    setLoading(true);
    setMessage(null);
    setOrders([]);
    const response = await getCustomerOrders(identifier);
    if (response.status === 500) {
      setMessage(
        response.message || "Some error occurred! Please try again Later."
      );
    }
    setLoading(false);
    if (response.data) {
      setOrders(response.data);
      if (response.data?.length === 0) {
        setMessage("No orders found for this customer.");
      }
    }
  };

  if (orders.length > 0) {
    return (
      <div style={styles.orderList}>
        <div style={styles.orderItemList}>
          <h2 style={styles.orderListHeading}>Last 5 Orders</h2>

          <ul style={{ listStyleType: "none", padding: "0" }}>
            {orders.map((order) => (
              <li key={order.id} style={styles.orderItem}>
                <h3
                  style={{ margin: "5px 0", color: "#333", fontSize: "18px" }}
                >
                  <strong>Order ID:</strong> {order.id}
                </h3>

                <p style={{ margin: "5px 0", color: "#555" }}>
                  <strong>Order Date:</strong> {order.date}
                </p>

                <p style={{ margin: "5px 0", color: "#555" }}>
                  <strong>Total Amount:</strong> {order.amount}
                </p>

                <DeliveryStatus order={order} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  } else
    return (
      <div style={styles.orderList}>
        <h1 style={styles.mainHeader}>See your Orders</h1>

        <input
          type="text"
          placeholder="Enter phone / email id"
          value={identifier}
          onChange={(e) => setIdentifier(e.target.value)}
          style={styles.inputField}
        />

        <br />

        {loading ? (
          <>
            <CircularProgress />
            <div style={styles.orderItemList}>
              <ul style={{ listStyleType: "none", padding: "0" }}>
                {Array.from({ length: 5 }).map((_, index) => (
                  <li style={styles.orderItem}>
                    <h3
                      style={{
                        margin: "5px 0",
                        color: "#333",
                        fontSize: "18px",
                      }}
                    >
                      <Skeleton variant="h3" width="50%" />
                    </h3>

                    <p style={{ margin: "5px 0", color: "#555" }}>
                      <Skeleton variant="caption" />
                    </p>

                    <p style={{ margin: "5px 0", color: "#555" }}>
                      <Skeleton variant="caption" />
                    </p>
                    <Skeleton variant="rectangular" height={50} />
                  </li>
                ))}
              </ul>
            </div>
          </>
        ) : (
          <>
            <button
              onClick={handleTrackOrder}
              disabled={!identifier}
              style={styles.fetchButton}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#007BFF")}
            >
              Track Order
            </button>
            {message && <p style={{ color: "red" }}>{message}</p>}
          </>
        )}
      </div>
    );
};

export default OrderList;
