import React, { useState, useEffect } from "react";
import OrderDisplay from "../components/OrderDisplay";
import { getOrderDetails } from "../actions/shopifyLambda";
import SkeletonProgress from "../components/SkeletonProgress";
import ReturnPolicy from "../components/ReturnPolicy";
import MyForm from "../components/OrderDisplay";

const HomePage = () => {
    const [customerName, setCustomerName] = useState("");
    const [exchangeHistory, setExchangeHistory] = useState([]);
    const [order, setOrder] = useState(null);
    const [email, setEmail] = useState("");
    const [isErrored, setIsErrored] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [pageLoading, setPageLoading] = useState(false);

    const callLambda = async (customerId, orderId) => {
        setPageLoading(true);
        const response = await getOrderDetails(customerId, orderId);
        if (response.success) {
            const orderDetails = response.data.orderDetails;
            const customer =
                "customer" in orderDetails ? orderDetails.customer : "";
            const email1 =
                !!customer &&
                "email" in customer &&
                !customer.email.includes("lbindia.myshopify.com")
                    ? customer.email
                    : "";
            setEmail(email1);
            const deliveryDetails = response.data.deliveryDetails;
            const formattedDeliveryDetails = deliveryDetails.value.map(
                (item) => {
                    const dateArray = item.date.split("/");
                    const formattedDate =
                        dateArray[1] + "/" + dateArray[0] + "/" + dateArray[2];

                    item.date = new Date(formattedDate);
                    return item;
                }
            );
            const exchangeHistoryDetails = response.data.exchangeHistoryDetails;
            const exchangeHistoryItems = exchangeHistoryDetails.reduce(
                (acc, o) => {
                    acc.push(...o.line_items);
                    return acc;
                },
                []
            );
            console.log(exchangeHistoryItems);
            orderDetails.line_items = orderDetails.line_items.filter(
                (p) =>
                    (!!formattedDeliveryDetails.find(
                        (item) =>
                            item.sku === p.sku &&
                            item.size === p.variant_title &&
                            item.status === "delivered" &&
                            new Date() <=
                                new Date(
                                    new Date(
                                        item.date.setDate(
                                            item.date.getDate() + 7
                                        )
                                    )
                                )
                    ) ||
                        String(customerId) === "6758706741535") &&
                    !exchangeHistoryItems.find(
                        (item) =>
                            item.sku === p.sku && item.size === p.variant_title
                    )
            );
            let line_items = orderDetails.line_items;
            if (String(customerId) === "6758706741535") {
                orderDetails.line_items = line_items.filter((p) => {
                    if (
                        orderDetails.line_items.length ===
                        formattedDeliveryDetails.length
                    ) {
                        const maxDate = new Date(
                            Math.max(
                                ...formattedDeliveryDetails.map((d) => d.date)
                            )
                        );
                        return (
                            new Date() <=
                            new Date(
                                new Date(
                                    maxDate.setDate(maxDate.getDate() + 15)
                                )
                            )
                        );
                    } else {
                        return true;
                    }
                });
            }
            setExchangeHistory(exchangeHistoryDetails);
            setOrder(response.data.orderDetails);
            setCustomerName(response.data.orderDetails.customer.first_name);
        } else {
            setIsErrored(true);
            if (response.data === "unauthorized") {
                setErrorText(
                    "Cannot find order details. Please go back and try again!!!"
                );
            } else {
                setErrorText("Error occured. Please refresh page");
            }
        }
        setPageLoading(false);
    };

    useEffect(() => {
        const allParams = new URL(window.location).searchParams;
        const customerId = allParams.get("customer");
        const orderId = allParams.get("order");
        callLambda(customerId, Number(orderId));
    }, []);
    // console.log("order", order);
    return (
        <>
            {/* <Header /> */}
            {/* <MyForm/> */}
            {/* <img src="https://drive.google.com/uc?export=view&id=17UThx-Q-a3xKGHimr2Dk8XAvL-eBBPIa"/> */}
            {isErrored && (
                <a href="https://littleboxindia.com/account">
                    <h1 className="container">{errorText}</h1>
                </a>
            )}
            {pageLoading ? <SkeletonProgress /> : ""}
            {order && (
                <div className="page">
                    <b>
                        {order.tags.includes("replacement") ? (
                            <p style={{ color: "red" }}>
                                Please note: You'll be charged an Rs. 250 for
                                your second exchange on the same order
                            </p>
                        ) : (
                            <p style={{ color: "red" }}>
                                An exchange fee of Rs 99 is applicable for
                                Exchange Order and Gift Cards
                            </p>
                        )}
                    </b>
                    <b>
                        Note: Any size exchange for Combo/Set has to be carried
                        out for the whole Combo/Set. Any single product from the
                        Combo/Set can't be exchanged for size
                    </b>
                    <h1 className="main-title">Exchange Portal</h1>
                    {/* <h2>Hello {customerName}</h2> */}
                    <h3 className="orderid-txt">Order ID: {order.name}</h3>
                    <OrderDisplay
                        order={order}
                        loading={pageLoading}
                        email={email}
                        exchangeHistory={exchangeHistory}
                    />
                </div>
            )}

            {/* <ReturnPolicy /> */}
            <br></br>
            {/* <div style={{ marginTop: "20" }}>
        <Typography
          style={{
            padding: "20",
            textAlign: "center",
            marginTop: "20",
            fontFamily: "Futura, sans-serif",
          }}
          variant="h7"
          component="p"
        >
          For any support please reach us at:{" "}
          <a
            href="mailto:customercare@littleboxindia.com"
            style={{
              color: "purple",
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            customercare@littleboxindia.com
          </a>
        </Typography>
      </div> */}
        </>
    );
};

export default HomePage;
