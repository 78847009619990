import React from "react";
import { Button } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";


const Header = () => {
    return (
        <header className="header_container">
            <a href="https://littleboxindia.com/">
                <img
                    src="/lblogo.jpeg"
                    className="logo"
                    alt="LittleBox India"
                />
            </a>

            {/* <ul>
                <li>
                    <Link to="/ticket">My Tickets</Link>
                </li>
                <li>
                    <Link to="/newTicket">New Ticket</Link>
                </li>
            </ul> */}

            <a
                href="https://littleboxindia.com/"
                style={{ textDecoration: "none" }}
            >
                <Button className="homebutton">
                    <HomeIcon style={{ color: "black" }} />
                    <div className="homebutton-inner">Home</div>
                </Button>
            </a>
        </header>
    );
};

export default Header;
