export const getCustomerOrders = async (identifier) => {
  // Make API call to fetch customer orders
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/getorderdetailsfromshopify";
  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ identifier }),
    });

    const data = await response.json();
    if (data.statusCode === 200) {
      return {
        status: 200,
        data: data.body.map((order) => ({
          id: order.name.slice(1),
          date: new Date(order.createdAt).toDateString(),
          amount: `₹ ${order.currentTotalPriceSet.presentmentMoney.amount}`,
          dispatched:
            order.displayFulfillmentStatus === "PARTIALLY_FULFILLED" ||
            order.displayFulfillmentStatus === "FULFILLED",
          cancelled: order.tags?.includes("manual_cancel"),
          rto: order.tags?.includes("RTO"),
        })),
      };
    } else {
      return {
        status: 500,
        message:
          "No Orders Found Please use the emil or phone number you used while creating the order",
      };
    }
  } catch (error) {
    return {
      status: 500,
      message: "Server error please try again later",
      error,
    };
  }
};
