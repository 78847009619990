import * as React from "react";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { Chip, Box, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  getAllTicketsOfCustomer,
  sendTicketUpdateToShopify,
} from "../actions/ticketsLambda";
import { auto } from "@popperjs/core";

import Loader from "../components/Loader";

const MyTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [pageError, setPageError] = useState(false);
  const [Ticketsempty, setTicketsEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      setLoading(true);
      const allParams = new URL(window.location).searchParams;
      const customerId = allParams.get("customer");
      console.log("here", customerId);
      await sendTicketUpdateToShopify(customerId);
      const data = await getAllTicketsOfCustomer(customerId);
      console.log("data", data);
      if (data.status === 200) {
        if (Object.keys(data.data).length > 0) {
          setTickets(data.data.tickets);
        } else {
          setTicketsEmpty(true);
        }
      } else {
        setPageError(true);
      }
      setLoading(false);
    })();
  }, []);
  const handleClick = (ticketId) => {
    console.log(ticketId);
    navigate(`/ticket/?id=${ticketId}`);
  };

  return (
    <>
      {loading && (
        <div className="load-overlay">
          <Loader />
        </div>
      )}
      {pageError ? (
        <Container>
          <h1>Something Went Wrong, Please Try Again</h1>
        </Container>
      ) : (
        <Box
          sx={{
            overflowX: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            // width: "100%",
            height: "100vh",
            borderRadius: "5px",
            paddingTop: "30px",
            backgroundColor: "#f7f7f7",
            border: "1px groove grey",
            overflow: "scroll",
          }}
        >
          <h1>My Tickets</h1>

          <Table
            sx={{
              minWidth: 450,
              border: "1px solid black",
              width: "90%",
              overflowX: "scroll",
              marginRight: auto,
              marginLeft: auto,
              marginTop: "50px",
              padding: "10px",
            }}
            aria-label="simple table"
          >
            <TableHead sx={{ background: "rgba(51, 51, 51, 78)" }}>
              <TableRow>
                <TableCell sx={{ color: "white" }}>Created</TableCell>
                <TableCell sx={{ color: "white" }} align="start">
                  Category
                </TableCell>
                <TableCell sx={{ color: "white" }} align="start">
                  Question
                </TableCell>
                <TableCell sx={{ color: "white" }} align="start">
                  Ticket ID
                </TableCell>
                <TableCell sx={{ color: "white" }} align="start">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            {Ticketsempty ? (
              <TableBody>
                <TableRow
                  key={400}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="center">
                    <h1>No ticket found</h1>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {Object.keys(tickets).map((row) => (
                  <TableRow
                    key={row.ticketId}
                    onClick={() => {
                      handleClick(row);
                    }}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:hover": {
                        bgcolor: "lightblue",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {new Date(tickets[row].created_at).toLocaleString()}
                    </TableCell>
                    <TableCell align="start">{tickets[row].category}</TableCell>
                    <TableCell align="start">{tickets[row].question}</TableCell>
                    <TableCell align="start">{row}</TableCell>

                    
                    <TableCell align="center">
                       <Chip label={tickets[row].status}
                             color= {tickets[row].status === "new" ? "info"
                                    :tickets[row].status === "open" ? "success"
                                    : "error"}/>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </Box>
      )}
    </>
  );
};

export default MyTickets;
